import { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import PrivateRoute from "./core/components/PrivateRoute";
import { useAuth } from "./auth/contexts/AuthProvider";

// Admin
const Admin = lazy(() => import("./admin/pages/Admin"));
const Faq = lazy(() => import("./admin/pages/Faq"));
const HelpCenter = lazy(() => import("./admin/pages/HelpCenter"));
const Home = lazy(() => import("./admin/pages/Home"));
const Profile = lazy(() => import("./admin/pages/Profile"));
const ProfileActivity = lazy(() => import("./admin/pages/ProfileActivity"));
const ProfileInformation = lazy(() => import("./admin/pages/ProfileInformation"));
const ProfilePassword = lazy(() => import("./admin/pages/ProfilePassword"));
const ProfileSignature = lazy(() => import("./admin/pages/ProfileSignature"));

// Auth
const Login = lazy(() => import("./auth/pages/Login"));

// Core
const Forbidden = lazy(() => import("./core/pages/Forbidden"));
const NotFound = lazy(() => import("./core/pages/NotFound"));
const UnderConstructions = lazy(
  () => import("./core/pages/UnderConstructions")
);


const AppRoutes = () => {
  const { userInfo } = useAuth();

  return (
    <Routes basename="/">
      <PrivateRoute path="" element={<Admin />}>
        <PrivateRoute path="/" element={<Home />} />
        <PrivateRoute path="faq" element={<Faq />} />
        <PrivateRoute path="help" element={<HelpCenter />} />
        <PrivateRoute path="profile" element={<Profile />}>
          <PrivateRoute path="/" element={<ProfileActivity />} />
          <PrivateRoute path="information" element={<ProfileInformation />} />
          <PrivateRoute path="password" element={<ProfilePassword />} />
          <PrivateRoute path="signature" element={<ProfileSignature />} />
        </PrivateRoute>
      </PrivateRoute>
      <Route path="login" element={!userInfo ? <Login /> : <Navigate to={`/admin`} replace />} />
      <Route path="under-construction" element={!userInfo ? <UnderConstructions /> : <Navigate to={`/admin`} replace />} />
      <Route path="403" element={<Forbidden />} />
      <Route path="404" element={<NotFound />} />
      <Route
        path="/"
        element={!userInfo ? <Navigate to={`/login`} replace /> : <Navigate to={`/admin`} replace />}
      />
      <Route
        path="*"
        element={<Navigate to={`/`} replace />}
      />
    </Routes>
  );
};

export default AppRoutes;
